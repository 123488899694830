import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1
      style={{
        fontSize: 80,
        fontFamily: "roboto mono",
        letterSpacing: "20px",
        marginBottom: "56px"
      }}
    >
      mimugo
    </h1>
    <p>Things will come...</p>
  </Layout>
);

export default IndexPage;
